var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isProfileLoading())?_c('Splash'):_c('WithSidebar',{scopedSlots:_vm._u([{key:"main",fn:function(ref){
var toggleSidebarLeft = ref.toggleSidebarLeft;
var toggleSidebarRight = ref.toggleSidebarRight;
return [_c('div',{staticClass:"inbox bg-light text-primary"},[(_vm.$mq === 'sm')?_c('TopNav',{attrs:{"color":"light"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('CurrentProfileBurger',{attrs:{"dark":""},on:{"click":toggleSidebarLeft}})]},proxy:true},{key:"right",fn:function(){return [_c('NotificationIcon',{on:{"click":toggleSidebarRight}})]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"inbox__conversations bg-light"},[_c('div',{staticClass:"inbox__search-bar"},[_c('BaseInput',{attrs:{"placeholder":"Search","round":"","text-align":"center"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"inbox__conversation-list"},[(_vm.$apollo.queries.myConversations.loading)?_c('div',{staticClass:"conversation"},[_c('LoadingEllipsis',[_vm._v("Loading conversations")])],1):(!_vm.myConversations.length)?_c('div',{staticClass:"conversation"},[_vm._v(" You have no active conversations. ")]):_vm._e(),_vm._l((_vm.filteredConversations),function(conversation){return _c('div',{key:conversation.id,staticClass:"conversation",on:{"click":function($event){return _vm.$router.push(
                                ("/" + (conversation.source.eventRequest
                                        ? 'request'
                                        : 'conversation') + "/" + (conversation.source.eventRequest
                                        ? conversation.source.eventRequest
                                        : conversation.id))
                            )}}},[_c('img',{staticClass:"conversation__left avatar-lg",attrs:{"src":_vm.getOther(conversation.members).avatar}}),_c('div',{staticClass:"conversation__right"},[_c('h3',{staticClass:"font-weight-medium",style:({
                                    display: 'grid',
                                    gridTemplateColumns: 'auto auto 1fr',
                                    justifyItems: 'start',
                                    columnGap: '0.5rem',
                                    alignItems: 'center'
                                })},[_c('span',{staticClass:"text-truncate",style:({ maxWidth: '100%' })},[_vm._v(_vm._s(_vm.getOther(conversation.members).name)+" ")]),_c('Badge',{style:({ display: 'inline-block', lineHeight: '16px' }),attrs:{"label":_vm.getOther(conversation.members).type,"color":"beige","size":"small"}}),(conversation.source.eventRequest)?_c('Badge',{style:({
                                        display: 'inline-block',
                                        lineHeight: '16px',
                                        whiteSpace: 'no-wrap'
                                    }),attrs:{"label":"Request","color":"beige","size":"small"}}):_vm._e()],1),_c('div',{staticClass:"conversation__bottom",style:({
                                    display: 'grid',
                                    gridTemplateColumns: '1fr repeat(2, auto)'
                                })},[_c('p',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(conversation.lastMessage.message)+" ")]),_c('p',{style:({
                                        marginLeft: '1rem',
                                        marginRight: '1rem'
                                    })},[_vm._v(" • ")]),_c('p',[_vm._v(" "+_vm._s(_vm.lastUpdated(new Date(conversation.updatedAt)))+" ")])])])])})],2)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }