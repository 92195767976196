<template>
    <header class="topnav text-primary bg-white" :class="classes">
        <div class="topnav__left">
            <slot name="left"></slot>
        </div>
        <div class="topnav__center">
            <router-link to="/">
                <img class="avatar-md" src="@/assets/logo_primary.svg" />
            </router-link>
        </div>
        <div class="topnav__right">
            <slot name="right"></slot>
        </div>
    </header>
</template>

<script>
export default {
    name: 'TopNav',
    emits: ['clicked'],
    props: {
        color: {
            default: 'light',
            validator(val) {
                return ['beige', 'white', 'light'].indexOf(val) !== -1
            }
        }
    },
    computed: {
        classes() {
            return {
                [`topnav--${this.color}`]: true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables';

.topnav {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 40px 20px 10px 20px;
    height: 90px;
    align-items: center;

    &__right {
        justify-self: end;
    }
    &--beige {
        background-color: $beige;
    }
    &--white {
        background-color: white;
    }

    &--light {
        background-color: $light;
    }
}

.fixed {
    position: absolute;
    width: 100%;
    top: 0;
    left: auto;
    z-index: 2;
}
</style>
