<template>
    <Splash v-if="isProfileLoading()" />
    <WithSidebar v-else>
        <template #main="{ toggleSidebarLeft, toggleSidebarRight }">
            <div class="inbox bg-light text-primary">
                <TopNav v-if="$mq === 'sm'" color="light">
                    <template #left>
                        <CurrentProfileBurger dark @click="toggleSidebarLeft" />
                    </template>
                    <template #right>
                        <!-- <img src="@/assets/exclamation_point.svg"/> -->
                        <NotificationIcon @click="toggleSidebarRight" />
                    </template>
                </TopNav>
                <div class="inbox__conversations bg-light">
                    <div class="inbox__search-bar">
                        <BaseInput
                            v-model="searchText"
                            placeholder="Search"
                            round
                            text-align="center"
                        />
                    </div>
                    <div class="inbox__conversation-list">
                        <div v-if="$apollo.queries.myConversations.loading" class="conversation">
                            <LoadingEllipsis>Loading conversations</LoadingEllipsis>
                        </div>
                        <div v-else-if="!myConversations.length" class="conversation">
                            You have no active conversations.
                        </div>
                        <div
                            v-for="conversation in filteredConversations"
                            :key="conversation.id"
                            class="conversation"
                            @click="
                                $router.push(
                                    `/${
                                        conversation.source.eventRequest
                                            ? 'request'
                                            : 'conversation'
                                    }/${
                                        conversation.source.eventRequest
                                            ? conversation.source.eventRequest
                                            : conversation.id
                                    }`
                                )
                            "
                        >
                            <img
                                :src="getOther(conversation.members).avatar"
                                class="conversation__left avatar-lg"
                            />
                            <div class="conversation__right">
                                <h3
                                    class="font-weight-medium"
                                    :style="{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto auto 1fr',
                                        justifyItems: 'start',
                                        columnGap: '0.5rem',
                                        alignItems: 'center'
                                    }"
                                >
                                    <span class="text-truncate" :style="{ maxWidth: '100%' }"
                                        >{{ getOther(conversation.members).name }}
                                    </span>

                                    <Badge
                                        :label="getOther(conversation.members).type"
                                        color="beige"
                                        size="small"
                                        :style="{ display: 'inline-block', lineHeight: '16px' }"
                                    />
                                    <Badge
                                        v-if="conversation.source.eventRequest"
                                        label="Request"
                                        color="beige"
                                        size="small"
                                        :style="{
                                            display: 'inline-block',
                                            lineHeight: '16px',
                                            whiteSpace: 'no-wrap'
                                        }"
                                    />
                                </h3>
                                <div
                                    class="conversation__bottom"
                                    :style="{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr repeat(2, auto)'
                                    }"
                                >
                                    <p class="text-truncate">
                                        {{ conversation.lastMessage.message }}
                                        <!-- Repl -->
                                    </p>
                                    <p
                                        :style="{
                                            marginLeft: '1rem',
                                            marginRight: '1rem'
                                        }"
                                    >
                                        •
                                    </p>
                                    <p>
                                        {{ lastUpdated(new Date(conversation.updatedAt)) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </WithSidebar>
</template>

<script>
import Badge from '@/components/Badge'
import WithSidebar from '@/components/layout/WithSidebar'
import TopNav from '@/components/TopNav'
import CurrentProfileBurger from '@/components/CurrentProfileBurger'
import NotificationIcon from '@/components/NotificationIcon'
import { toTimeSinceStringShort } from '@/utils/dateUtils.js'
import gql from 'graphql-tag'
const GET_CONVERSATIONS = gql`
    query MyConversations {
        myConversations {
            id
            createdAt
            updatedAt
            lastMessage
            createdBy
            updatedBy
            source
            variant
            members {
                id
                type
                name
                media {
                    profile {
                        url
                    }
                }
                isMyProfile
                isInitiator
                avatar @client
            }
        }
    }
`

export default {
    name: 'Inbox',
    components: {
        CurrentProfileBurger,
        WithSidebar,
        Badge,
        TopNav,
        NotificationIcon
    },
    inject: ['isProfileLoading', 'getMyCurrentProfile'],
    apollo: {
        myConversations: {
            query: GET_CONVERSATIONS,
            pollInterval: 15000,
            fetchPolicy: 'network-only'
        }
    },
    data() {
        return {
            searchText: '',
            myConversations: []
        }
    },
    computed: {
        currentProfileConversations() {
            return this.myConversations
                .filter(({ members }) => {
                    return members.some((member) => member.id === this.getMyCurrentProfile().id)
                })
                .sort((a, b) => {
                    return new Date(b.updatedAt) - new Date(a.updatedAt)
                })
        },
        filteredConversations() {
            return this.currentProfileConversations.filter((conversation) =>
                this.getOther(conversation.members).name.includes(this.searchText)
            )
        }
    },
    methods: {
        lastUpdated(date) {
            return toTimeSinceStringShort(date)
        },
        getMe(members) {
            return members.find((member) => member.id === this.getMyCurrentProfile().id)
        },
        getOther(members) {
            return members.find((member) => member.id !== this.getMyCurrentProfile().id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.text-truncate {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.inbox {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: auto;
    &__header {
        height: 90px;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: 2rem 1rem 1rem;
        & > :last-child {
            justify-self: end;
        }
    }
    &__conversations {
        // overflow: hidden;
        height: 100%;
        display: grid;
        grid-template-rows: auto 1fr;
    }
    &__conversation-list {
        // overflow: auto;
        height: 100%;
        padding-bottom: 100px;
    }

    &__search-bar {
        padding: 1rem;
    }
}

.conversation {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 0.5rem;
    padding: 1rem;
    align-items: center;
    // width: 100%;

    &__bottom {
        // overflow: hidden;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: start;
        // grid-template-columns: repeat(3, 1fr);
    }
}
</style>
