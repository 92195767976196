<template>
    <IconWithNotification @click="$emit('click', $event)">
        <template #icon>
            <img src="@/assets/exclamation_point.svg" class="icon-lg" />
        </template>
        <template v-if="unreadNotificationCount" #notch-content>
            {{ unreadNotificationCount }}
        </template>
    </IconWithNotification>
</template>

<script>
import IconWithNotification from './IconWithNotification'
// import { getUnreadNotificationCountByType } from '@/utils/notifications.js'
export default {
    name: 'NotificationIcon',
    components: {
        IconWithNotification
    },
    inject: ['getMyCurrentProfile', 'myNotificationsQuery'],
    emits: ['click'],
    computed: {
        unreadNotificationCount() {
            return this.myNotificationsQuery().data.filter((notification) => !notification.isRead)
                .length
        }
    }
}
</script>
